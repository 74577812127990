import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from 'react'

import * as styles from '../../assets/css/architects.module.css'
import Layout from '../../components/Layout'

/* childImageSharp thumb width should be in sync with css class artfulContent grid-template-columns: .5fr 2fr 150px */
export const essayQuery = graphql`
  query {
    allArchitect(filter: {town: {eq: "true"}}) {
      nodes {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
            )
          }
        }
      }
    }
  }
`


const TownArchitects = () => {
  const content = useStaticQuery(essayQuery)
  const images = []
  content.allArchitect['nodes'].forEach((element) => {
    const imageData = element['image']
    images.push(imageData)
  })
  images.sort(function(a, b) {
    const nameA = a.childImageSharp.gatsbyImageData.images.fallback.src.toUpperCase(); // ignore upper and lowercase
    const nameB = b.childImageSharp.gatsbyImageData.images.fallback.src.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
  const image1 = getImage(images[0])
  const image2 = getImage(images[1])
  const image3 = getImage(images[2])
  const image4 = getImage(images[3])
  const image5 = getImage(images[4])
  const image6 = getImage(images[5])
  const image7 = getImage(images[6])
  const image8 = getImage(images[7])
  const image9 = getImage(images[8])
  const image10 = getImage(images[9])
  const image11 = getImage(images[10])

  return (
    <Layout>
      <section>
        <h1 className='headline'>The Role of the Town Architect</h1>
        <section>
          <h2>About Seaside</h2>
          <div>
            <div></div>
            <p>The concept of the Town Architect was developed during the first Seaside Charrette held in the summer of 1983. 
            During the charrette it was concluded that someone must supervise and interpret the implementation of the Seaside Plan and Code. 
            It was also hoped that those who purchased properties in Seaside would hire the Town Architect to design their cottages. 
            The first Town Architects were members of the “Night Crew”, those working at the 1983 charrette, who were responsible for the 
            layout of streets and early buildings. Over the years Seaside has evolved and so has the role of the Town Architect. In the 
            beginning the Town Architect would work with homeowners and builders to draft their cottages. Now, with Seaside nearly complete, 
            the Town Architect often acts as the architect on record or works with the homeowners chosen architect on code and regulations.
            </p>
            <h3><Link to='/essays/town-archtiects-history'>Read The History of the Town Architects</Link></h3>
            <h2>Town Architects</h2>
            <ul className={styles.table2}>
              <li>1983</li><li><Link to="#1">Teófilo Victoria</Link></li>
              <li>1983</li><li><Link to="#2">Derrick Smith</Link></li>
              <li>Aug. 1984–Oct. 1984</li><li><Link to="#3">Ernesto Buch</Link></li>
              <li>1984–1985</li><li><Link to="#4">Thomas Christ</Link></li>
              <li>1986</li><li><Link to="#5">John Montague Massengale, AIA</Link></li>
              <li>1987–1988</li><li><Link to="#6">Victoria Casasco</Link></li>
              <li>Feb. 1988–June 1990</li><li><Link to="#7">Scott Merrill</Link></li>
              <li>1990</li><li><Link to="#8">Charles Warren</Link></li>
              <li>1990–1996</li><li><Link to="#9">Richard Gibbs</Link></li>
              <li>1996–1999</li><li><Link to="#10">Gordon Burns</Link></li>
              <li>2005–2009</li><li><Link to="#11">Leo Casas</Link></li>
              <li>2009</li><li><Link to="#12">Ty Nunn</Link></li>
            </ul>
            <hr />
            <h2 id="1">Teófilo Victoria</h2>
            <div className={styles.image}><GatsbyImage image={image4} alt='Teófilo Victoria' /></div>
            <p>Teófilo Victoria began his education in Germany studying naval architecture. He came to the United States in the early 70s to study 
              architecture at the Rhode Island School of Design, from which he earned his Bachelor of Architecture in 1979. He went on to Columbia 
              University and graduated in 1982 with a Master’s Degree in Architecture and Urban Design. His first contact with Seaside came during 
              his years as a graduate student. In 1980 he worked for Arquitectonica, the firm in which Andrés Duany and Elizabeth Plater-Zyberk 
              were partners at the time. He was involved in work on the initial drawings for Seaside before enrolling at Columbia University and 
              finishing his graduate education.
            </p>
            <p>In 1982 Andrés Duany asked Teófilo Victoria, along with Ernesto Buch— Duany’s cousin—and Victoria Casasco, to come and join the 
              Seaside charette. The idea of the charette—one rarely, if ever, used since the Beaux Arts—was one of many ideas that Duany and 
              Plater-Zyberk were trying out as part of their experiment in comprehensive town planning. Another idea which was part of their 
              grand experiment was the position of Town Architect. Teófilo Victoria became the first Town Architect of Seaside in 1983. His 
              experience in the position made it a role which has continued to be an important part of Seaside to the present day.
            </p>
            <p>Being the first person to assume the role of Town Architect in Seaside was certainly exciting—everyone in Seaside at that time was 
              thrilled by the novelty of what they were creating and the proposition of it actually coming to fruition and taking form – but it was 
              also a great challenge. The position was untested, without any specific duties, expectations or restrictions. Today, however, 
              Victoria considers his role in defining the responsibilities of the Town Architect for the future to be his greatest accomplishment 
              in that position. They looked to lofty examples while trying to decide what role exactly the Town Architect should have in the designing 
              of towns and the building of cities, examining the roles of Giotto in Florence and Schinckel in Berlin. The value of having a single 
              architect on site to work with developers, engineers and surveyors simultaneously was always evident however and Victoria claims his 
              greatest contribution to Seaside “was in helping to recover this tradition in city building that had [been] abandoned by the discipline 
              of architecture.”
            </p>
            <p>One of the greatest challenges Victoria faced while Town Architect was the threat of the unknown—and much was unknown at that time. 
              Any kind of town building, let alone the type of town they were proposing, was unheard of in the early 80s. The implementation and 
              practice of this brave new idea, even more so than the idea itself, was unpredictable and uncertain. Though it had the unwavering 
              support of the small collection of people who were in Seaside from the beginning, including Teófilo Victoria, at that time it was 
              only “a compellingly beautiful setting… the forlorn quality and melancholy of what could be understood as wilderness.”
            </p>
            <p>As someone who was working in Seaside from its earliest beginnings, one would expect Victoria’s thoughts on Seaside to be much 
              different today, nearly thirty years later. However, Victoria asserts that he sees “Seaside today much as [he] saw it then. 
              [He] understood then that the value of what Andrés, Lizz and Robert [were] proposing was nothing less than the return of Architecture 
              to Urbanism.” This new way of thinking about architecture as inextricably linked to the design of cities is what led to the New 
              Urbanism, of which Victoria was an early contributor. He describes New Urbanism as “the only coherent and systematic proposal for 
              city building today… there is no other proposal that can address the construction of the city in a predictable and measured manner.”
            </p>
            <p>Today Teófilo Victoria is an architect and urbanist and a Principal in delaGuardiaVictoria Architects and Urbanists, the firm he 
              owns with his partner Maria de la Guardia. They have recently been awarded a Palladio Award for Excellence in Classical and Traditional 
              Design, a Charter Award from the Congress for The New Urbanism, and the Phillip Trammell Schutze Award for their work. DLGV Architects 
              and Urbanists practice classical architecture and traditional urbanism in the United States and abroad. Victoria has taught at Harvard 
              University, Cornell University, and the University of Miami where he has also served as Undergraduate Program Director and Graduate 
              Program Director. He is a member of the Congress for New Urbanism and the Institute for Classical Architecture and Classical America.
            </p>
            <h3>Selected Projects</h3>
            <ul className={styles.table}>
              <li><Link to="/structures/s414">Market Pavilion, Town Center, 1983</Link></li>
              <li><Link to="/surveys/teofilo-victoria">View Teófilo Victoria’s Town Architect Survey</Link></li>
            </ul>
            <h2 id="3">Ernesto Buch</h2>
            <div className={styles.image}><GatsbyImage image={image2} alt='Ernesto Buch' /></div>
            <p>Ernesto Buch has been a lifelong proponent of Classical and Traditional architecture, from his youth in Cuba and Miami, through 
              his education at Miami and Harvard, and into his professional career as an architect. This very specific ideology was one he 
              shared with Andrés Duany, who invited Buch to Seaside during its earliest days to be part of the “Night Crew.” There, he was involved
               in the foundations of Seaside: the plan, the code and the first structures. In August of 1983, he took on a new role in the small 
               town, as Seaside’s second Town Architect.
            </p>
            <p>Buch describes his involvement in Seaside and his tenure as Town Architect as a pivotal experience in his professional career. 
              Also among his most important moments are his first projects as a practicing architect, which were built in Seaside during his tenure. 
              The very first building was the Tupelo Street Beach Pavilion, the first of what became a tradition of beach pavilions built in 
              Seaside. A true representative of the vernacular style, today it remains an icon of the New Urbanist community that Seaside has become.</p>
            <p>Buch considers his time in Seaside as fundamental to his development as a traditional urbanist as well. His philosophy on urbanism is 
              that all urbanism is traditional, and it should need no qualification. “Modernist planning principles are anti-urban…suburban sprawl of 
              the last 65 years is the anti-city.” He feels that Seaside is an attempt to design the traditional small town of America. As a built 
              project, it may not match the ideal designed for it, but after nearly thirty years it can be judged and evaluated for both its successes 
              and its failures. It can be a learning institute for the practitioners of today.
            </p>
            <p>Buch started his own firm, Ernesto Buch Architect, Inc., in 1987, and today he remains a partner there. He has offices in Punta 
              Cana, Dominican Republic and in New Haven Connecticut, where he first started. His work consists almost exclusively of private residences 
              by the sea in the Classical style. The firm‘s work has been published in various publications and has been given several awards, 
              including the Arthur Ross Award in 1994 and the Charles A. Barrett Award in 2008.
            </p>
            <h3>Selected Projects</h3>
            <ul className={styles.table}>
              <li><Link to='/structures/s412'>Public Works Building, 1984</Link></li>
              <li><Link to='/surveys/ernesto-buch'>View Ernesto Buch’s Town Architect Survey</Link></li>
            </ul>
            <h2 id="4">Thomas Christ</h2>
            <div className={styles.image}><GatsbyImage image={image5} alt='Thomas Christ' /></div>
            <p>Tom Christ did his first work in an architectural firm before he even began his architectural education, working in a local office 
              as a draftsman during high school summers. He received his formal education from Florida A&M, graduating with a Bachelor’s degree, 
              and returning immediately to work in various areas of Miami. It was during this early period in his career that he went to work for 
              Andrés Duany and Elizabeth Plater-Zyberk when they were first starting their firm, DPZ. His time at DPZ was also his introduction 
              to Seaside; he worked on the final stages of land planning for the town and on some of the prototypical houses also worked on by 
              the “Night Crew.”
            </p>
            <p>It was during his time working with DPZ that Christ met Robert Davis. In 1984 Davis asked Christ to come to Seaside to serve as Town 
              Architect for a year. So Christ and his wife moved to the beach, planning to stay only a year, not knowing that they would end up 
              staying for another thirty years. While serving as Town Architect, Christ received numerous commissions very quickly, including 
              several of the early houses on Tupelo Street and Ruskin Street, along with the <Link to='/structures/s215'>Savannah Street Beach 
              Pavilion</Link>. He also worked on some of the buildings in the work area, including a tower on axis with Tupelo Street, called 
              “Poet’s Tower.”
            </p>
            <p>Although his year-long tenure as was very busy, Christ considers his greatest accomplishment as Town Architect his time spent working 
              with some of the excellent architects at Seaside at that time, particularly Andrés Duany. They all shared many of what he feels were 
              the greatest challenges of working in Seaside so early in its existence: designing houses using a new type of code, while also defining 
              a specific southern vernacular style and striving to keep clients content. Christ observes that although there were plenty of examples 
              of the northern Florida style in the area—both good and bad—many architects of the early years sought to develop their own styles and 
              interpretations of the vernacular. This endeavor led them to push the envelope and test how far the design code could go. He believes 
              that although the perception was that architects should not stray too far from the intent of the code, both Robert Davis and Andrés 
              Duany encouraged and even desired pushing the boundaries of the code to see just what it could become. Although this was challenging 
              for the many young architects working in Seaside, and did not always yield successful designs, it was an important aspect of early 
              Seaside.
            </p>
            <p>Thirty years later, Christ looks back fondly on the very early days of Seaside. It was a time when architects and contractors were 
              all working together and having fun, experimenting constantly with everyone equally excited to try something new and different. Every 
              day ended with volleyball on the beach. It was a good time for everyone, but as the town began to grow, likewise the responsibilities 
              and the attitude of Seaside shifted to become more work-focused. Christ also found it fascinating to watch the town grow over the 
              last thirty years: from a small town with simple little cottages to a larger town of more complex structures. The whole attitude of 
              the town has changed, and he has found it interesting to see how exposure to different influences can affect the growth of a place 
              over time. In Seaside those influences were mostly economical, and Christ points out a visual division in the town from the 
              eastern-most portions, the oldest part of Seaside, to the newer western half of the town. As land values rose, the clients began 
              pushing for “bigger, better, taller” then they had before. This phenomenon was widespread in Seaside and changed the look and feel 
              of the town, which started so small, to something bigger but, in his opinion, not as special. Even so, it still remains a beautiful 
              and unique place.
            </p>
            <p>Today, Tom Christ is partner and owner of his own firm, Christ & Associates, located in Santa Rosa Beach, Florida. His firm primarily 
              does custom residential work, as well as commercial projects, interior design, and landscaping. Christ is still active in Seaside as 
              well, having done eighty-five private residences there to date. Their work has been showcased in many books and magazines and has 
              been featured on the HGTV cable network.
            </p>
            <h3>Selected Projects</h3>
            <ul className={styles.table}>
              <li><Link to='/structures/s215'>Savannah Street Beach Pavilion, 1985</Link></li>
              <li><Link to='/surveys/thomas-christ'>View Thomas Christ’s Town Architect Survey</Link></li>
            </ul>
            <h2 id="5">John Montague Massengale, AIA</h2>
            <p>John Montague Massengale AIA has won awards for architecture, urbanism, architectural history and historic preservation, from 
              organizations and publications ranging from Progressive Architecture and Metropolitan Home, to the National Book Award Foundation 
              (with the first architecture book to be nominated for a National Book Award), to multiple chapters of the American Institute of 
              Architects. A founding member of the Congress for the New Urbanism, he is the Chair of the New York chapter, CNU New York, and a 
              former board member of the ICAA (Institute for Classical Art & Architecture) and FCWC (Federated Conservationists of Westchester 
              County).
            </p>
            <p>A self-described “Recovering Architect,” he likes all sorts of towns, cities and buildings, but designs Classical buildings and 
              traditional neighborhoods and towns. With Robert A.M. Stern, he was co-author of The Anglo-American Suburb (London, AD / New York, 
              St. Martin's Press, 1981 — a primary source book at the Seaside charrette) and New York 1900, Metropolitan Architecture & Urbanism 
              1890-1915 (New York, Rizzoli, 1983). With a grant from the Driehaus Foundation, he and Victor Dover are writing Street Design, 
              The Art & Practice of Making Complete Streets (New York, Wiley, 2012).
            </p>
            <p>He has taught at the Universities of Miami and Notre Dame, and was educated at Harvard College (A.B.) and the University of 
              Pennsylvania (M.Arch.).
            </p>
            <h3>Selected Projects</h3>
            <ul className={styles.table}>
              <li>Atheneum Hotel, Project (Never Built), 1986–1987</li>
              <li><Link to='/structures/s269'>Savannah Sands</Link></li>
            </ul>
            <h2 id="6">Victoria Casasco</h2>
            <div className={styles.image}><GatsbyImage image={image7} alt='Victoria Casasco' /></div>
            <p>Victoria Casasco received her undergraduate degree from the Rhode Island School of Design and a Masters of Architecture from 
              Columbia University Graduate School of Architecture and Planning. During her early career, Casasco worked with Duany Plater-Zyberk 
              Architects on the Town of Seaside project. She served as Seaside Town Architect from 1987 to 1988 and is the only female Town 
              Architect to date. She formed CASASCOstudio in 1987 and has done many acclaimed projects, in Seaside and elsewhere. For the 
              last two decades Casasco has been a professor of architecture. She first taught at Southern California Institute of Architecture, 
              but for the last decade has been serving as an Assistant Professor at Yale University. Her research and her projects have been 
              widely published and have garnered her many awards for design excellence.
            </p>
            <h3>Selected Projects</h3>
            <ul className={styles.table}>
              <li><Link to='/structures/s290'>Appell house, 1987-1989</Link></li>
              <li>Walton-DeFuniak Library, Project (Never Built), 1987</li>
              <li>Bud and Alley’s Oyster Bar, Town Center, 1988</li>
            </ul>
            <h2 id="7">Scott Merrill</h2>
            <div className={styles.image}><GatsbyImage image={image6} alt='Scott Merrill' /></div>
            <p>Scott Merrill spent most of his formal education and early career along the northern half of the east coast. He completed his 
              undergraduate studies at the University of Virginia, receiving a BA in 1979, and went on to graduate school at Yale University, 
              where he received a Masters of Architecture in 1984. After completing his education he went to Washington D.C. and worked for 
              residential firms McCartney Lewis, and Cass and Pinnell. It was while working for Cass and Pinnell that he was introduced to 
              Andrés Duany, a meeting that would eventually take him to the Southern panhandle to work and to live.
            </p>
            <p>In 1988, Scott Merrill was asked to be the sixth Town Architect of Seaside. He moved with his wife to Seaside, Florida in 
              February of 1988 and remained as Town Architect until the summer of 1990. While there he worked on numerous projects in Seaside, 
              many residential, for both Robert Davis, the founder of Seaside, and for private clients. The amount of work he was able to do 
              while in Seaside is perhaps the most rewarding aspect of the position for Merrill. He notes that in the Northeast there were a 
              lot of people chasing a relatively small number of projects, but in being willing to go to a place that seemed like it was “at 
              the edge of the world” he was working with far fewer people and doing many more projects. Coming to Seaside gave him the 
              opportunity to design—and to build—houses along some of the most beautiful waterfront properties in the country, a far cry from 
              what a young architect would have been working on in the already-established northeast. Merrill notes that this opportunity 
              was one that he shared with many other young architects who were given the opportunity to build when they may not otherwise 
              have had that chance.
            </p>
            <p>Although the opportunity to build so much as a young architect was a great honor, it also came with challenges. One of the 
              biggest challenges was trying to keep the houses modest. The early houses didn’t come near the limits of the buildable area 
              of their lots, but as the lot prices went up, the houses tended to get larger and fancier as well. This was a detriment to 
              the public spaces of Seaside, as it allowed the relationship of public to private to fall out of balance. The effort to maintain 
              this balance for as long as possible was one of the more difficult aspects of being Seaside’s Town Architect.
            </p>
            <p>Arriving in the late 80s, Merrill was not among the very first to begin his career in Seaside, but he has still witnessed much 
              of its growth. Today, he is grateful to have been there for at least twenty-three of its thirty years. He feels that it would 
              be impossible for someone coming today to imagine what it was like in the 80s; luckily he doesn’t have to imagine it. Whereas 
              Seaside today comes with the benefits of improved schools and hospitals as well as easier access to food and other necessities; 
              Seaside in the 80s was just a uniquely fun place to live and work. There were more people working in Seaside than living in it. 
              His Seaside experience would not have been nearly as nice had he not had the opportunity to live and work there together. The 
              higher cost of living there today would make that difficult, but that is the flip side to the better amenities.
            </p>
            <p>After leaving his post as Town Archtiect, Merrill moved to Vero Beach, opening a practice to work on the new Duany Plater 
              Zyberk master plan for the project of Windsor. He continues to work with DPZ today, a relationship which has brought Merrill 
              many opportunities around the world. They have coordinated on projects in New Zealand, Russia, Edinburgh, the Caribbean and 
              the UAW. While their work is architectural, it is done as a complement to the urban plans devised by DPZ. These projects—ranging 
              from character studies to programming and capacity planning—add diversity to the already varied work of Merrill’s firm.
            </p>
            <p>Merrill, along with his partners George Pastor and David Colgan, has also designed churches, town halls, mixed use buildings, 
              rowhouses, apartments, university buildings, public gardens, club master plans, and several dozen private residences in urban 
              sites, historic districts and in conservation districts throughout the eastern half of the United States. He and his firm have 
              received three national AIA awards, including one in urban design for their first group of buildings and gardens. The firm has 
              also been recognized for its design 14 times by the Florida AIA. Recently Merrill Pastor Colgan received the Arthur Ross Award 
              by the Institute for Classical Architecture in New York, in recognition of a body of work that has contributed to traditional 
              American design.
            </p>
            <h3>Selected Projects</h3>
            <ul className={styles.table}>
              <li><Link to='/structures/s304'>Perceptions, 599 Forest, 1989</Link></li>
              <li><Link to='/structures/s392'>Honeymoon Cottages, Route 30-A and East Ruskin Street, 1989</Link></li>
              <li><Link to='/structures/s117'>Dune House, 2423 Country Road 30-A, 1989–1990</Link></li>
              <li><Link to='/structures/s164'>Sweet Spot, 2418 County Road 30-A, 1990</Link></li>
              <li><Link to='/structures/s227'>Motor Court and Mini-Storage, Quincy Circle, 1989–1990</Link></li>
              <li><Link to='/structures/s236'>Seaside Chapel, Ruskin Square, 1999–2001</Link></li>
              <li><Link to='/structures/s304'>View Scott Merrill’s Town Architect Survey</Link></li>
            </ul>
            <h2 id="8">Charles Warren</h2>
            <div className={styles.image}><GatsbyImage image={image10} alt='Charles Warren' /></div>
            <p>Charles Warren has been a practicing architect and an avid author for more than thirty years. His broad experience began with an 
              education first at the California Institute of the Arts, then at Skidmore College and finally at Columbia University from which
              he received his Master’s Degree in Architecture in 1980. He worked as an Associate in the firm of Robert A.M. Stern for nearly a 
              decade before moving on into his own firm, Charles Warren Architect, p.c., of which he is still the principal and lead designer 
              today.
            </p>
            <p>In 1987 Warren entered the academic arena once again when he won the Muschenheim Fellowship at the University of Michigan College 
              of Architecture and Urban Planning. While teaching design and architectural theory as an associate professor at the University of 
              Michigan, he met Robert Davis, the founder of Seaside. Davis invited him to come to Seaside, and after leaving the University of 
              Michigan Warren traveled down to Florida to become the seventh Town Architect of Seaside in 1990.
            </p>
            <p>He remained as Seaside’s Town Architect until 1991. During that time he reviewed more than a hundred projects, some which were 
              already underway and some which were just beginning. This was a time of great growth for Seaside, and the role of Town Architect as 
              enforcer of the Urban Code was an important, but also a delicate, one. Warren found that working with other architects who were in 
              turn working with the desires of their own clients made a challenging combination. Changes often had to be made and those suggestions 
              and corrections had to be made and evaluated so that everyone ended up satisfied with the results in the end. This was that greatest 
              challenge for Warren as town architect, but his own work in Seaside helped to balance that challenge with a sense of accomplishment. 
              During his time as Town Architect, Warren was involved with the building of several utility buildings, shops along the beach, and a 
              new swimming area. Although many of these places no longer exist today, they were an important part of Seaside during its explosive 
              growing years. He also began work on three houses which would eventually be completed in Seaside.
            </p>
            <p>After leaving his post in Seaside, Warren returned to his own firm in Manhattan. He stayed connected to Seaside however, doing 
              the design for Natchez Park. Looking at Seaside today, he describes it as more interesting than later New Urbanist projects, saying 
              that as an early exemplar of its kind of town it may be less perfect that later works, but its diversity of style and all of its many 
              variations are precisely what make it so interesting. DPZ—and everyone who followed in their footsteps—learned from everything in 
              Seaside; it is full of lessons, both good and bad, and anyone who wants to study urbanism today really must visit it, whether they 
              like it or not.
            </p>
            <p>Charles Warren considers the role of urbanist as a necessary addendum to that of architect and as such considers himself both. 
              Looking at Seaside from the standpoint of urbanism, he considers it both a standout example and a part of a long standing tradition. 
              He calls Seaside an early turning point in what has become a long path to the rediscovery of theories of town planning which were 
              lost or abandoned in the mid 19th century. In this respect it is radically different, but in harkening back to these long lost ways
              of thinking it also falls in line with traditions in holiday town buildings, small town buildings, and even in some respects 
              suburban planning in this country.
            </p>
            <p>In addition to being a practicing architect, Charles Warren is an academic and an author. He has taught at the University of 
              Michigan, at the Catholic University of America in Washington, D.C. and at the Institute for Classical Architecture and Classical 
              America. He has written including a recent monograph on the work of Carrère & Hastings Architects and a book and several articles 
              about urbanism. Today his firm works all along the East Coast, from New England down to Florida. They do both residential and 
              commercial architecture, but specialize in buildings done in unique and challenging locations and situations: historic districts, 
              planned communities and environmentally sensitive areas. Their work has been published in books and magazines in both the United 
              States and in Europe.
            </p>
            <h3>Selected Projects</h3>
            <ul className={styles.table}>
              <li><Link to='/structures/s380'>Dahlgren House, 108 Odessa</Link></li>
              <li><Link to='/structures/s245'>Wilder by the Sea, 2444 County Road 30-A</Link></li>
              <li><Link to='/structures/s217'>Natchez Park</Link></li>
              <li><Link to='/structures/s397'>Seaside Post Office (unbuilt)</Link></li>
              <li><Link to='/surveys/charles-warren'>View Charles Warren’s Town Architect Survey</Link></li>
            </ul>
            <h2 id="9">Richard Gibbs</h2>
            <div className={styles.image}><GatsbyImage image={image1} alt='Richard Gibbs' /></div>
            <p>In the span of his nearly forty-year career, Richard Gibbs has been an architect, an urbanist, and most recently a preservationist. 
              He was educated at the Rhode Island School of Design, from which he received his Bachelor of Architecture in 1974. His early work, 
              straight out of design school, was as an urbanist, but his direction changed when he came to Seaside in 1986 to build a home with 
              his partner, Randy Harelson, in the New Urbanist community on the coast. In 1990 Robert Davis extended an offer for Gibbs to become 
              the Town Architect of Seaside, a role which he would accept and remain in for six years, making him the longest-tenured of all 
              Seaside Town Architects to date.
            </p>
            <p>Gibbs came to Seaside at a time when building plans being submitted for review were in a lull. He spent most of his time working 
              on his own private firm, R.M. Gibbs Design, which employed architect Ty Nunn, a future Town Architect of Seaside. During his time 
              at Seaside he completed more than sixteen residential projects for the town. As the Town Architect he was also the enforcer of 
              the Urban Code and often found the balance between the desires of his clients and the regulations of the Code to be a fulfilling 
              challenge in his work. Among his many houses in Seaside is “Sea Forever,” a house right on the coast which Robert Davis later 
              purchased to make his home.
            </p>
            <p>His experience in Seaside turned him from an urbanist by education to a New Urbanist by experience, and when he left Seaside he 
              took that experience to another New Urbanist town being developed nearby called Rosemary Beach. He served as Town Architect of 
              Rosemary Beach for an additional seven years, reviewing hundreds of plans and designing several more public and private 
              buildings. His work in New Urbanism was to last several more years as he went on to help create two more communities: Draper 
              Lake Coastal Village in Florida and Brewster Point in Maine.
            </p>
            <p>After fifteen years working in urban design, Gibbs moved to Louisiana to pursue yet another architectural interest, preservation. 
              He currently lives in the LeJeune House, a two hundred year old plantation home listed on the National Register of Historic 
              Places, and is working to preserve and restore the buildings and the grounds. He also serves on the Board of Directors for 
              the Louisiana Trust for Historic Preservation. His firm, R.M. Gibbs Design, today focuses on the “restoration of old homes 
              and the design of new traditionally-styled buildings to fit seamlessly into historic sites and established neighborhoods.”
            </p>
            <h3>Selected Projects</h3>
            <ul className={styles.table}>
              <li><Link to='/structures/s212'>Sea Forever, 1992 County Road 30-A</Link></li>
              <li><Link to='/structures/s213'>Idyll by the Sea, 2040 County Road 30-A</Link></li>
              <li><Link to='/structures/s386'>Banana Republic, 842 Forest</Link></li>
              <li><Link to='/structures/s366'>Sea Biscuit, 750 Forest</Link></li>
              <li><Link to='/structures/s108'>Life is Good, 664 Forest</Link></li>
              <li><Link to='/surveys/richard-gibbs'>View Richard Gibbs’ Town Architect Survey</Link></li>
            </ul>
            <h2 id="10">Gordon Burns</h2>
            <div className={styles.image}><GatsbyImage image={image3} alt='Gordon Burns' /></div>
            <p>Gordon Burns first became interested in the architectural world by building playhouses and forts as a child, and drawing his own 
              floor plans. He completed his Bachelor of Architecture degree along with many additional graduate courses in Urban Design at the 
              University of Illinois, later attaining a Master of Business Administration there, and a Real Estate degree at Georgia State 
              University. As his career developed, Burns found himself practicing the principles of New Urbanism through architecture and 
              real estate development with his own firm: Gordon Burns & Associates of Chicago. On his first visit to Seaside in 1990, 
              Burns recognized the significance of the community’s unique vision, and immediately took on two roles: first, of purchasing 
              a lot at 12 Odessa Street to design his own home on site, and second, of helping to implement the Seaside vision via tangible 
              construction.
            </p>
            <p>In 1992, Burns was elected both to serve on the Seaside Town Council as the Rental Homeowners’ Representative, and as President 
              of the Odessa Street Homeowners’ Association, both of which roles he occupied until 1999. After becoming Executive Vice-President 
              and Chief Operating Officer for Real Estate in 1996, Burns reviewed and approved design, construction, and financing for the 
              community, and served on the Downtown Seaside Merchants Association Board of Directors for three years, a period of great 
              commercial expansion.
            </p>
            <p>Also in 1996-1999, Burns served as a Town Architect of Seaside, and spent his time reviewing designs and remodeling efforts to 
              be in line with the Seaside Code. He recognized the diversity and creative expression that this Code offered, and applied its 
              design axioms of vernacular authenticity and use of natural materials. Burns considers himself a New Urbanist, and credits his 
              architectural inspiration within this philosophy to Andres Duany, with whom he worked during his ten years at Seaside. He has 
              observed Seaside’s ability to influence many projects worldwide, but also recognizes a more personal influence on the work he 
              continues to produce as owner and CEO of Gordon Burns & Associates.
            </p>
            <p>Burns’ firm has won several awards, including the State of Illinois Madigan Award for Best Public Building for the Chemistry and 
              Biological Sciences Building at Illinois State University, and an AIA honor award for the Marvin Hult Health Education Building 
              in Peoria, IL. His work has been published in Vogue Magazine and various architectural journals. In addition to his contributions 
              to the built environment, Burns also designed and patented ArchiNet, which unites architectural firms across geographic boundaries 
              to promote collaborative work. Burns is a licensed architect in CA, FL, GA, IL, MA, VA, TX, IN, IA, and AZ, and is NCARB certified.
            </p>
            <h2 id="11">Leo Casas</h2>
            <div className={styles.image}><GatsbyImage image={image8} alt='Leo Casas' /></div>
            <p>Braulio Casas served Seaside’s second-longest tenure as Town Architect; after being appointed by Robert Davis in February 2005 
              he served until February 1, 2009. He initially got to know Robert Davis, and Seaside, while involved with a master planning 
              charette to guide the development of Seaside’s central square area. During that time Seaside was without a Town Architect, and 
              the lack of an advisor to oversee building was beginning to affect the style and quality of the town, a fact which Casas felt 
              was unacceptable. His goal coming into the office of Town Architect was to change the current attitude from one that was not 
              focused enough on quality and style to one which would strongly emphasize quality in aesthetics and would encourage new 
              builders to pursue traditional methods of building. His goal—and what he believes was his greatest accomplishment as Town 
              Architect—was to create a greater sense of respect for classical and vernacular architecture within the context of Seaside’s 
              Urban Code.
            </p>
            <p>His goals were not achieved without great challenges, however. As the Town Architect of Seaside, Casas describes himself as 
              the “lightning rod” at the center of all controversy. As the medium between the Town Founder, Robert Davis, and the inhabitants 
              of Seaside, Casas found mediating the two realms to be particularly difficult. He describes the political component of being 
              Town Architect to be the most challenging aspect of his lengthy tenure.
            </p>
            <p>Although his tenure as Seaside’s Town Architect has expired, Braulio Casas has not strayed far. He still operates his own 
              architectural practice out of Seaside, desigining civic, residential and commercial projects in the region. In 2010 he was 
              awarded a Palladio Award for one of the homes he designed in the town of Seaside. A trained architect, he also describes 
              himself as an urbanist, as he believes that “one cannot, indeed one ought not to practice architecture, without having a 
              complete understanding and appreciation of the dialectic between architecture and urbanism.” He gives frequent talks about 
              Seaside and the implications of its architecture and urbanism in both fields worldwide.
            </p>
            <p>Although Casas has moved on from his role of Town Architect of Seaside, he recognizes that its story will continue indefinitely. 
              Speaking about his thoughts on Seaside today, Casas said that it “will always be a work in progress… it will never really be 
              complete and will depend greatly on the good stewardship by both its citizens and the Town Architects yet to come.”
            </p>
            <h3>Selected Projects</h3>
            <ul className={styles.table}>
              <li>Forest Street Residence, 2009</li>
              <li><Link to='/structures/s302'>Bowers Residence</Link></li>
              <li><Link to='/structures/s95'>Breedlove Residence</Link></li>
              <li><Link to='/structures/s90'>Bud & Alley’s Pizza Bar</Link></li>
              <li><Link to='/structures/s91'>Bud & Alley’s Taco Bar</Link></li>
              <li><Link to='/structures/s133'>Fiorenze Residence</Link></li>
              <li><Link to='/surveys/leo-casas'>View Leo Casas’ Town Architect Survey</Link></li>
            </ul>
            <h2 id="12">Ty Nunn</h2>
            <div className={styles.image}><GatsbyImage image={image9} alt='Ty Nunn' /></div>
            <p>Ty Nunn’s relationship with Seaside began before his career as a practicing architect. As a student at Auburn University in the 
              late 1980s, Nunn was geographically close to Seaside during its earliest days. He was able to visit some of its most iconic structures, 
              both under construction and newly completed. As a fourth year undergraduate, his interest in Seaside extended to the academic level; 
              he traveled there to undertake an urban study of the new town.
            </p>
            <p>In 1991, Nunn graduated from Auburn University with his Bachelor’s Degree in Architecture. The following year he moved to Seaside to 
              work for Richard Gibbs, who was the Town Architect of Seaside at the time. He worked for Gibbs for a number of years before eventually 
              beginning his own firm, florida haus, in 2001. He continued working on residential projects throughout Florida, and in May of 2009 
              he became Seaside’s tenth Town Architect, a role he still fills today.
            </p>
            <p>As someone who has been actively watching Seaside for nearly twenty- five years, Nunn presents a unique perspective on Seaside’s 
              past, present and future. As a student, Nunn says that Seaside was “all about the potential. There was enough there to see what the 
              potential really was, and now, at least residentially, it is almost fully developed.” Today he looks back on Seaside and sees its 
              evolution, starting from a very simple vernacular and evolving over time into very detailed and sophisticated homes with a 
              still- growing town center. In the future, Nunn sees many of the structures built as temporary solutions many years ago gradually 
              being replaced with permanent buildings.
            </p>
            <p>Not only has the physical structure of the town evolved, but its demographic has as well. Seaside has a vocal, ensconced body of 
              homeowners as well as a flourishing commercial town center. While some may see these two entities as being at odds with one another, 
              Nunn feels that they are both integral to what makes Seaside such a unique place. He expects that even as it continues to develop 
              and evolve Seaside will always remain a holiday town. However, as the geographic and emotional center of County Road 30-A, the 
              energy that radiates through Seaside is, at least in part, due to the vibrancy of the public realm in its main plazas. This energy 
              is an important part of its appeal to homeowners, guests, and tourists alike.
            </p>
            <p>Over the past two years as Town Architect, Nunn has had the opportunity to oversee several projects in Seaside. One of his greatest 
              honors during this time was working with Léon Krier to execute his plaza in Seaside. Currently, florida haus is also working on 
              several renovations in Seaside, a by-product of the recent trend in Seaside of purchasing original cottages and renovating to bring 
              them up to date or to tailor them to the individual needs of the new owners. His work in the vernacular of the Florida panhandle 
              and its surrounding regions has also led him to work throughout the Southeast, including a new residence in New Orleans that he 
              is currently designing in a vernacular New Orleans style.
            </p>
            </div>
        </section>
      </section>
    </Layout>
  )
}

export default TownArchitects
